import 'core-js';
import 'regenerator-runtime/runtime';

/**============================================================
*   [gnav]
*   description : グローバルナビの処理を記述
============================================================*/
var accordion = new Vue({
  el: '#vue-gnav',
  data: {
    isGnavOpened: false,
    isTopPage: (function(){
      let _href = 'recruit';
      var segments = window.location.pathname.split('/');
      var toDelete = [];
      for (var i = 0; i < segments.length; i++) {
        if (segments[i].length < 1) {
          toDelete.push(i);
        }
      }
      for (var i = 0; i < toDelete.length; i++) {
        segments.splice(i, 1);
      }
      var filename = segments[segments.length - 1];
      return (filename == _href) ? true : false;
    })(),
    isSP: (function(){
      var mql = window.matchMedia("(min-width: 767px)");
      if (mql.matches) {
        return false;
      }else{
        return true;
      }
    })()
  },
  mounted: function(){
    // window.addEventListener('scroll', this.headerScroll);
  },
  methods: {
    isGnavClose: function(){
      this.setIsSP();
      this.isGnavOpened = false;
    },
    setIsSP: function(){
      var mql = window.matchMedia("(min-width: 767px)");
      if (mql.matches) {
        this.isSP = false;
      }else{
        this.isSP = true;
      }
    },
    isToggleGnav: function(){
      this.setIsSP();
      if(this.isGnavOpened){
        this.isGnavOpened = false;
      }else{
        this.isGnavOpened = true;
      }
    }
  }
})

/**============================================================
*   [ArchiveOpened]
*   description : 「もっと見る」ボタンの処理を記述
============================================================*/
if($("#vue-blog-nav").length > 0){
  var archiveNav = new Vue({
    el: '#vue-blog-nav',
    data: {
      isArchiveOpened: false
    },
    methods: {
      isToggleArchive: function(){
        if(this.isArchiveOpened){
          this.isArchiveOpened = false;
        }else{
          this.isArchiveOpened = true;
        }
      },
      mounted: function(){
      },
      beforeEnter: function(el) {
        el.style.height = '0';
      },
      enter: function(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      beforeLeave: function(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      leave: function(el) {
        el.style.height = '0';
      }
    }
  })
}
